import React from "react";
import { handleChange, handleSubmit } from "../../utils/netlify";
import SocialIcons from "../SocialIcons";
import { css } from "@emotion/core";
import WufooForm from "../WufooForm";

const Contact = () => {
  const [state, setState] = React.useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
    "bot-field": "",
  });
  return (
    <section className="relative bg-white" id="contact">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-primary" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div
          className="bg-gradient py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
          css={css`
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: -40px;
              right: -40px;
              border-width: 40px;
              border-style: solid;
              border-color: transparent transparent #f9fafb transparent;
              transform: rotate(45deg);
            }
            @media (min-width: 1024px) {
              &:after {
                border-color: transparent transparent white transparent;
              }
            }
          `}
        >
          <div className="text-center md:text-left max-w-lg mx-auto">
            <h2 className="text-4xl leading-8 font-bold tracking-tight text-white sm:leading-9">
              Get in touch
            </h2>
            <p className="mt-3 text-lg leading-6 text-white">
              For questions and inquiries, please contact us via phone or
              contact form submission.
            </p>
            <dl className="mt-8 text-base leading-6 text-white">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <a
                    href="https://goo.gl/maps/g2YFh5XQrCuPyQN66"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:underline"
                  >
                    226 S Spring St.
                    <br /> Beaver Dam, WI 53916
                  </a>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex justify-center md:justify-start">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span className="ml-3">
                    <a
                      className="underline hover:underline"
                      href="tel:+1-920-219-4545"
                    >
                      (920) 219-4545
                    </a>
                  </span>
                </dd>
                <dt className="sr-only">Phone number</dt>
                <dd className="flex justify-center md:justify-start">
                  <SocialIcons
                    containerClassName={"space-x-4 mt-12"}
                    iconClassName={"border border-white rounded"}
                    bgTransparent={true}
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
          <WufooForm formHash={"z1y2l7wc0f2wgpt"} userName={"interquest"} />

          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
