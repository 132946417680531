import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const About = () => {
  const image = useStaticQuery(graphql`
    query BuildingImage {
      file(relativePath: { eq: "building.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `).file;

  return (
    <section className={"container section text-gray-700"} id={"about"}>
      <div className={"flex flex-wrap mt-8 md:mt-16 md:pb-8"}>
        <div className={"py-12 px-2 w-full md:w-1/2 md:pr-4 items-center"}>
          <h2
            className={
              "text-3xl md:text-4xl lg:text-5xl leading-tight font-thin text-gray-900 text-center md:text-left"
            }
          >
            About
          </h2>
          <div className={"mt-8 md:pr-4 space-y-4"}>
            <p>
              As a family owned business, we started our store with two goals in
              mind:
            </p>
            <ol className={"list-decimal pl-8"}>
              <li>
                Getting you the gear that helps you get out, be active, and
                create an active lifestyle.
              </li>{" "}
              <li>
                Creating a friendly environment with outstanding customer
                service that will be second to none.
              </li>
            </ol>
            <p>
              Putting these two together has created the ultimate shopping
              experience where customers become friends since 2015.
            </p>
          </div>
        </div>
        <div
          className={
            "order-first md:order-last w-full md:w-1/2 mt-4 flex flex-col justify-center"
          }
        >
          <Img
            fluid={image.childImageSharp.fluid}
            className={"shadow md:rounded full-bleed md:full-bleed-disable"}
            alt={"Front of store"}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
