import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";

const CustomApparel = () => {
  const image = useStaticQuery(graphql`
    query CustomApparelImage {
      file(relativePath: { eq: "your-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `).file;

  return (
    <section className={"container section"}>
      <h2
        className={
          "text-2xl md:text-4xl lg:text-5xl leading-tight font-thin text-gray-900 text-center"
        }
      >
        Custom Active & Business Wear
      </h2>
      <div className={"flex flex-wrap mt-8 md:mt-16"}>
        <div className={"px-2 w-full md:w-1/2 md:pr-4 items-center"}>
          <p
            className={
              "mt-8 md:mt-0 text-xl md:text-2xl lg:text-3xl leading-tight font-thin text-gray-900 text-center md:text-left"
            }
          >
            Looking for custom apparel?
            <br />
            Look no further!
          </p>

          <p className={"mt-8 text-gray-700"}>
            We offer a huge variety of brands, sizes, and styles to help you
            achieve the look you are going for. Whether it is a company outing,
            day to day operations, or a sports team: we’ve got you covered. We
            even custom generate order forms for you that use your logo on the
            specific items you choose for the form. Our goal is to be your one
            stop shop. No hassle of buying and taking items else where to get
            customized. We work very closely with an embroiderer and a screener
            to ensure each customer gets high quality items. You order with us
            and pick up with us — we take care of the rest.
          </p>
        </div>
        <div
          className={"order-first px-24 md:px-12 md:order-last w-full md:w-1/2"}
          css={css`
            position: relative;
            perspective: 1000px;
          `}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            className={"max-w-sm mx-auto"}
            css={css`
              transform: rotateX(10deg) rotateY(-10deg);
            `}
          />
        </div>
      </div>
    </section>
  );
};

export default CustomApparel;
