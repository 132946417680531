import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { css } from "@emotion/core";

const sliderSettings = {
  accessibility: true,
  dots: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 3000,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 1500,
      },
    },
  ],
};

const Brands = () => {
  const logos = useStaticQuery(graphql`
    query BrandLogos {
      allFile(filter: { relativeDirectory: { eq: "brand-logos" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `).allFile.edges;

  return (
    <section className={"container section"}>
      <h2
        className={
          "text-3xl md:text-4xl lg:text-5xl leading-tight font-thin text-gray-900 text-center"
        }
      >
        Get the brands you{" "}
        <span
          className={
            "inline-block italic bg-gradient font-bold text-white px-4 antialiased transform -skew-x-6 rounded shadow"
          }
        >
          know and love
        </span>
      </h2>
      <div className={"px-8"}>
        <Slider
          {...sliderSettings}
          css={css`
            .slick-track {
              display: flex;
              align-items: center;
            }
          `}
          className={"mt-8 md:mt-16 max-w-3xl mx-auto"}
        >
          {logos.map(({ node }, i) => (
            <div key={i} className={"px-8 outline-none"}>
              <picture>
                <source
                  type={"image/webp"}
                  srcSet={node.childImageSharp.fluid.srcSetWebp}
                  sizes={node.childImageSharp.fluid.sizes}
                />
                <source
                  srcSet={node.childImageSharp.fluid.srcSet}
                  sizes={node.childImageSharp.fluid.sizes}
                />
                <img
                  sizes={node.childImageSharp.fluid.sizes}
                  srcSet={node.childImageSharp.fluid.srcSet}
                  src={node.childImageSharp.fluid.src}
                  className={"outline-none"}
                  alt={node.name}
                />
              </picture>
            </div>
          ))}
        </Slider>
      </div>
      <p
        className={"mt-12 md:mt-16 max-w-3xl mx-auto text-gray-700 text-center"}
      >
        Every product sold at Active Outfitters is hand picked for quality.
        <br />
        Not only do we carry all of the top brands you expect, but we actively
        search for new brands to market that offer the same quality. Innovation
        is a good thing — we want to bring you that with the companies we
        choose.
      </p>
    </section>
  );
};

// Selection is key in making sure we have the gear that will cover you
// from head to toe. We offer tons of variety in footwear, tops, bottoms,
//     and accessories. Our top customer feedback comes from our wide selection
// of colors and styles.
//
//     As a small business, we are able to feature more items from companies
// compared to the big box stores. If we do not have something that you are
// looking we will special order it for you.

export default Brands;
