import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import Brands from "../components/sections/Brands";
import CustomApparel from "../components/sections/CustomApparel";
import Gallery from "../components/sections/Gallery";
import About from "../components/sections/About";
import Contact from "../components/sections/Contact";

const IndexPage = ({ data, location }) => {
  const { heroImage } = data;

  return (
    <Layout>
      <SEO
        title={"Home"}
        keywords={[
          `active outfitters`,
          `active clothing brands`,
          `active apparel`,
          `sports clothing`,
          `custom business apparel`,
        ]}
        pathname={location.pathname}
      />
      <Hero image={heroImage} />
      <Brands />
      <CustomApparel />
      <Gallery />
      <About />
      <Contact />
    </Layout>
  );
};

const Hero = ({ image }) => {
  return (
    <section className={"relative py-48"}>
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.6);
          z-index: -1;
          content: "";
        `}
      >
        <Img
          fluid={image.childImageSharp.fluid}
          alt={"Woman jumping in an urban setting"}
          className={"absolute top-0 left-0 h-full w-full"}
          css={css`
            z-index: -2;
          `}
          fadeIn={false}
          loading={"eager"}
        />
      </div>
      <div className={"relative text-center"}>
        <h1
          className={"text-4xl sm:text-5xl font-bold text-white antialiased"}
          css={css`
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
          `}
        >
          Active Outfitters
        </h1>
        <p
          className={
            "max-w-lg mx-auto text-white text-lg md:text-xl leading-tight antialiased"
          }
          css={css`
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
          `}
        >
          Come shop and find all the top name brands to help get you out and be
          active.
        </p>
      </div>
    </section>
  );
};

export const indexPageQuery = graphql`
  query IndexPageQuery {
    heroImage: file(relativePath: { eq: "woman-urban-jumping.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default IndexPage;
