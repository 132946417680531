import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Nav = () => {
  const [open, setOpen] = useState(false);

  const { fixed } = useStaticQuery(graphql`
    query NavLogoQuery {
      file(relativePath: { eq: "logo-tr-white.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `).file.childImageSharp;
  // urls are all lower case to match Links component and Contact component ids
  const links = [
    {
      title: "Gallery",
      url: "/#gallery",
    },
    {
      title: "About",
      url: "/#about",
    },
    {
      title: "Contact",
      url: "/#contact",
    },
  ];

  return (
    <>
      {/* nav container */}
      <div className="fixed top-0 left-0 z-50 w-screen py-3 px-4 sm:px-6 lg:px-8 bg-gradient border-b border-white antialiased">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
          <div className="flex items-center flex-grow flex-shrink-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <AnchorLink to="/" className={"flex items-center"}>
                <Img fixed={fixed} alt={"Logo"} />
              </AnchorLink>
              <div className="flex items-center md:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  onClick={() => setOpen(!open)}
                  aria-label="Open/Close Menu"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-10 md:pr-4">
            {links.map((link, i) => (
              <AnchorLink
                to={link.url}
                className={`${
                  i > 0 ? "ml-8" : ""
                } font-medium text-white hover:underline focus:outline-none focus:underline transition duration-150 ease-in-out`}
                key={i}
                // onClick={(e) => {
                //   e.preventDefault();
                //   scrollTo(link.url);
                // }}
              >
                {link.title}
              </AnchorLink>
            ))}
          </div>
        </nav>
      </div>

      <div
        className={`${
          open ? "fixed" : "hidden"
        } top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden w-screen z-50`}
      >
        <div className="rounded-lg shadow-md">
          <div className="rounded-lg bg-white shadow-xs overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-end">
              <div className="-mr-2 -mt-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 focus:text-gray-600 transition duration-150 ease-in-out"
                  onClick={() => setOpen(!open)}
                  aria-label="Open/Close Menu"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {links.map((link, i) => (
                //   Wrapper for onClick to work (cannot attach onClick to Link)
                <div onClick={() => setOpen(!open)} key={i}>
                  <AnchorLink
                    to={link.url}
                    className={`${
                      i > 0 ? "mt-1" : ""
                    } block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:underline hover:bg-gray-50 focus:outline-none focus:underline focus:bg-gray-50 transition duration-150 ease-in-out`}
                  >
                    {link.title}
                  </AnchorLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
