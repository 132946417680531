import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { css } from "@emotion/core";

const Gallery = () => {
  const galleryImages = useStaticQuery(graphql`
    query Gallery {
      allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            name
            childImageSharp {
              original: fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              thumbnail: fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `).allFile.edges;

  return (
    <section className={"container section"} id={"gallery"}>
      <div
        css={css`
          .image-gallery-image {
            height: 400px !important;
          }
          .fullscreen img.image-gallery-image {
            height: auto !important;
            max-height: 100vh !important;
          }
          .image-gallery-slide-wrapper {
            width: 100% !important;
          }
        `}
      >
        <ImageGallery
          thumbnailPosition={"left"}
          showThumbnails={false}
          showPlayButton={false}
          autoPlay={true}
          items={galleryImages.map(({ node }) => ({
            original: node.childImageSharp.original.src,
            originalAlt: node.name,
            thumbnail: node.childImageSharp.thumbnail.src,
            thumbnailAlt: node.name + " thumbnail",
            ...node.childImageSharp.original,
          }))}
        />
      </div>
    </section>
  );
};

export default Gallery;
